import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 200px;
  padding: 100px 0;

  @media (max-width: 1024px) {
    grid-gap: 100px;
    padding: 50px 0;
  }

  @media (max-width: 544px) {
    grid-gap: 80px;
    padding: 40px 0;
  }
`

const Info = styled.div`
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.unit.getRem(21)};
  font-weight: 400;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 552px;

  h1 {
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(37)};
    margin: 0;
  }

  .description {
    margin-top: 24px;
    font-size: ${props => props.theme.unit.getRem(21)};
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(18)};

    h1 {
      font-size: ${props => props.theme.unit.getRem(24)};
    }
  }
`

const Article = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 80px;

  @media (max-width: 840px) {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    grid-gap: 48px;

    ${Info} {
      order: -1;
    }
  }

  img {
    max-width: 440px;
  }
`

const FunnelButton = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 28px;
  box-shadow: 0 2px 20px 0 rgba(209, 219, 231, 0.5);
  align-items: center;
  margin-right: auto;
  margin-bottom: 32px;

  div {
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 8px 16px;

    img {
      margin-right: 16px;
      height: 40px;
      width: 40px;
    }
  }

  @media (max-width: 544px) {
    div {
      font-size: ${props => props.theme.unit.getRem(18)};
    }
  }
`

const Content = () => {
  return (
    <Wrapper>
      <Article>
        <Info>
          <h1>Funnels that convert</h1>
          <div className="description" style={{ marginBottom: '48px' }}>
            Easy, fast, optimized funnels that AUTOMATICALLY qualify leads,
            capture their contact info, and book appointments for you. Our
            industry leading conversion rates, combine with our one-click call
            booking technology and automated appointment algorithm to convert
            40% to 68% of cold leads into booked appointments… on autopilot!
          </div>

          <FunnelButton>
            <div>
              <img
                alt="survey funnels"
                src={require('../../images/survey-funnels.svg')}
              />
              Survey Funnels
            </div>
          </FunnelButton>
        </Info>

        <picture>
          <source
            srcSet={require('../../images/funnels@3x.png')}
            media="(min-width: 1200px)"
          />
          <source
            srcSet={require('../../images/funnels@2x.png')}
            media="(min-width: 800px)"
          />
          <img
            srcSet={require('../../images/funnels.png')}
            alt="How it works!"
          />
        </picture>
      </Article>

      <Article>
        <picture style={{ maxWidth: 429 }}>
          <source
            srcSet={require('../../images/automated-4channel-followup@3x.png')}
            media="(min-width: 1200px)"
          />
          <source
            srcSet={require('../../images/automated-4channel-followup@2x.png')}
            media="(min-width: 800px)"
          />
          <img
            srcSet={require('../../images/automated-4channel-followup.png')}
            alt="How it works!"
            style={{ maxWidth: 429 }}
          />
        </picture>
        <Info>
          <h1>Automated 4 channel Follow up</h1>
          <div className="description">
            Let's face it, leads don't always respond from the first touch and
            you never know whether they want you to message them via text, email
            or voicemail. You don't have to be a wizard anymore, just let the
            system automatically follow up on leads and just talk to leads when
            they're ready to respond.
          </div>
        </Info>
      </Article>
    </Wrapper>
  )
}

export default Content
