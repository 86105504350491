import React from 'react'
import styled, { withTheme } from 'styled-components'

import Navbar from '../Navbar'
import Modal from '../Modal'

import PlayButton from '../../images/play-button.inline.svg'
import BACKGROUND from '../../images/hero-background.svg'

const Wrapper = styled.div`
  background: rgb(7, 101, 226);
  background: linear-gradient(
    180deg,
    rgba(7, 101, 226, 1) 0%,
    rgba(1, 9, 64, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100wv;
  margin-bottom: 100px;
  position: relative;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 544px) {
    margin-bottom: 40px;
  }
`

const Background = styled.img`
  width: 100%;
  position: absolute;
  bottom: -1px;
  user-select: none;
  z-index: 1;

  @media (min-width: 1440px) {
    bottom: -10%;
  }

  @media (min-width: 1920px) {
    bottom: -14%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 744px;
  margin-top: 88px;
  z-index: 2;

  @media (max-width: 768px) {
    margin-top: 8px;
    padding: 20px 20px 0 20px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(45)};
  font-weight: 600;
  margin: 0 0 24px 0;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(26)};
  }
`

const Description = styled.h3`
  color: ${props => props.theme.colors.white};
  font-family: CircularStd;
  font-size: ${props => props.theme.unit.getRem(21)};
  font-weight: 400;
  margin: 0 0 48px 0;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(18)};
    margin: 0 0 32px 0;
  }
`

const GetStartedButton = styled.a`
  background-color: ${props => props.theme.colors.green};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(18)};
  font-weight: 600;
  text-transform: uppercase;
  width: 256px;
  padding: 16px 0;
  transition: background-color 250ms ease-in;
  text-transform: uppercase;

  :hover {
    background-color: ${props => props.theme.colors.hover.green};
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.green};
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(12)};
    width: 184px;
    padding: 8px 0;
  }
`

const Label = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(16)};
  margin-top: 8px;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(12)};
  }
`

const HowItWorks = styled.div`
  position: relative;
  max-width: 760px;
  margin-top: 64px;

  img {
    width: 100%;
  }
`

const HowItWorksButton = styled.div`
  background-color: transparent;
  border: solid 2px ${props => props.theme.colors.green};
  border-radius: 5px;
  color: ${props => props.theme.colors.green};
  font-size: ${props => props.theme.unit.getRem(18)};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 208px;
  max-height: 40px;
  margin: auto;
  cursor: pointer;
  user-select: none;
  transition: background-color 250ms ease-in;

  svg {
    margin-right: 8px;
    height: 16px;
    width: 16px;
  }

  :hover {
    background-color: ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};

    path,
    polygon,
    g {
      fill: ${props => props.theme.colors.white};
    }
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.green};
    color: ${props => props.theme.colors.white};
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(10)};
    text-transform: uppercase;
    width: 168px;
    max-height: 32px;
  }
`

const Hero = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <Wrapper>
        <Navbar />

        <Background src={BACKGROUND} />

        <Container id="hero-container">
          <Title>Turn leads into calls on autopilot</Title>
          <Description>
            ContactSmarter helps companies, agencies and salespeople turn leads
            into calls through automation and funnels that are battle-tested to
            convert the highest so that you can stay focused on what you do
            best. Closing.
          </Description>
          <GetStartedButton
            href="https://app.contactsmarter.com/signup"
            style={{ textDecoration: 'none' }}
          >
            Get started for free
          </GetStartedButton>
          <Label>7-day free trial</Label>

          <HowItWorks>
            <picture>
              <source
                srcSet={require('../../images/how-it-works@3x.png')}
                media="(min-width: 1200px)"
              />
              <source
                srcSet={require('../../images/how-it-works@2x.png')}
                media="(min-width: 800px)"
              />
              <img
                srcSet={require('../../images/how-it-works.png')}
                alt="How it works!"
              />
            </picture>

            <HowItWorksButton onClick={() => setIsOpen(true)}>
              <PlayButton /> See how it works
            </HowItWorksButton>
          </HowItWorks>
        </Container>
      </Wrapper>
      {isOpen && <Modal isOpen={isOpen} onRequestClose={setIsOpen} />}
    </>
  )
}

export default withTheme(Hero)
