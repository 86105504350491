import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;

  @media (max-width: 544px) {
    padding: 40px 0;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.unit.getRem(37)};
  margin: 0;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(24)};
  }
`

const Description = styled.div`
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.unit.getRem(21)};
  margin-top: 24px;
  margin-bottom: 48px;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(18)};
    margin-bottom: 32px;
  }
`

const Button = styled(Link)`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(14)};
  font-weight: 600;
  text-align: center;
  width: 200px;
  padding: 16px 0;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;

  transition: background-color 250ms ease-in;

  :hover {
    background-color: ${props => props.theme.colors.hover.primary};
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.primary};
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(12)};
    text-transform: uppercase;
    width: 168px;
    padding: 8px 0;
  }
`

const MoreFeatures = () => {
  return (
    <Wrapper>
      <Title>There's a whole lot more</Title>
      <Description>
        Appointment Booking, Pipelines, Round Robins, Reminders, Reports... and
        More!
      </Description>
      <Button to="/features" style={{ textDecoration: 'none' }}>
        Discover features
      </Button>
    </Wrapper>
  )
}

export default MoreFeatures
