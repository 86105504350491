import React from 'react'
import styled, { keyframes } from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'

import Cross from '../images/cross.inline.svg'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Overlay = styled.div`
  background-color: rgba(21, 28, 38, 0.9);
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  animation: ${fadeIn} 250ms;

  .close {
    position: absolute;
    cursor: pointer;
    right: 32px;
    top: 32px;
    width: 24px;
    max-height: 24px;

    path,
    polygon,
    g {
      fill: ${props => props.theme.colors.white};
    }
  }
`

const Container = styled.div`
  font-family: CircularStd;
  text-align: center;
  width: 976px;

  @media (max-width: 976px) {
    width: 768px;
  }

  @media (max-width: 768px) {
    width: 544px;
  }

  @media (max-width: 544px) {
    width: 296px;
  }

  .video {
    margin-bottom: 48px;
    max-width: 976px;
  }
`

const GetStartedButton = styled.a`
  background-color: ${props => props.theme.colors.green};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(18)};
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 16px 24px;
  transition: background-color 250ms ease-in;
  text-transform: uppercase;

  :hover {
    background-color: ${props => props.theme.colors.hover.green};
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.green};
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(12)};
    width: 184px;
    padding: 8px;
  }
`

const Modal = ({ isOpen, onRequestClose }) => {
  return (
    <Overlay isOpen={isOpen}>
      <Cross onClick={() => onRequestClose(false)} className="close" />
      <OutsideClickHandler onOutsideClick={() => onRequestClose(false)}>
        <Container>
          <div className="video">
            <div
              dangerouslySetInnerHTML={{
                __html: `
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/54ujvo28ej?videoFoam=true" title="Untitled Video" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`,
              }}
            />
          </div>
          <GetStartedButton
            href="https://app.contactsmarter.com/signup"
            style={{ textDecoration: 'none' }}
          >
            Get started for free
          </GetStartedButton>
        </Container>
      </OutsideClickHandler>
    </Overlay>
  )
}

export default Modal
