import React, { useEffect } from 'react'

import SEO from '../components/SEO'
import Container from '../components/Container'
import Footer from '../components/Footer'

import Hero from '../components/Home/Hero'
import Content from '../components/Home/Content'
import MoreFeatures from '../components/Home/MoreFeatures'
import Testimonials from '../components/Testimonials'
import GetStartedBanner from '../components/GetStartedBanner'

const IndexPage = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Segment tracking page
      window.analytics.track('HomePage Loaded')
      // Facebook pixel tracking page
      window.fbq('track', 'HomePage Loaded')
    }
  }, [])

  return (
    <React.Fragment>
      <SEO title="ContactSmarter" />
      <Hero />

      <Container>
        <Content />
      </Container>

      <Container>
        <MoreFeatures />
      </Container>

      <Container>
        <Testimonials />
      </Container>

      <Container>
        <GetStartedBanner />
      </Container>

      <Footer />
    </React.Fragment>
  )
}

export default IndexPage
